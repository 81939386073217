<template>
  <div class="card card-custom gutter-b p-5">
    <div class="py-2">
      <b-button
        class="float-right"
        variant="success"
        @click="$bvModal.show('modal-tambah')"
        >Tambah Materi</b-button
      >
    </div>
    <div style="overflow-x: auto; overflow-y: hidden" class="pt-2">
      <table
        id="materi"
        class="table table-striped table-bordered"
        style="width: 100%"
      >
        <thead>
          <tr>
            <th style="width: 5%">No</th>
            <th style="width: 30%">Judul Materi</th>
            <th>Deskripsi</th>
            <th>Pemateri</th>
            <th>Aksi</th>
          </tr>
        </thead>
        <tbody>
          <!-- diubah sesuai kebutuhan tabel -->
          <tr v-for="(row, i) in getMateri" :key="i">
            <td class="text-center"></td>
            <td>{{ row.judul }}</td>
            <td>{{ row.deskripsi }}</td>
            <td>{{ row.nama_pemateri }}</td>
            <td>
              <b-button
                @click="showModalFileMateri(row.id)"
                size="sm"
                variant="primary"
                class="m-1"
                v-b-tooltip.hover
                title="File Materi"
                ><i class="flaticon-file"></i
              ></b-button>
              <b-button
                @click="goToSoal(row.id)"
                size="sm"
                variant="success"
                class="m-1 pr-2"
                v-b-tooltip.hover
                title="Soal"
                ><i class="flaticon-list"></i
              ></b-button>
              <b-button
                @click="showModal(row.id)"
                size="sm"
                variant="warning"
                class="m-1 pr-2"
                v-b-tooltip.hover
                title="Edit Materi"
                ><i class="flaticon-edit"></i
              ></b-button>
              <b-button
                @click="deleteMateri(row.id)"
                class="m-1 pr-2"
                size="sm"
                variant="danger"
                title="Hapus Materi"
                ><i class="flaticon2-trash"></i
              ></b-button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <b-modal id="modal-tambah" title="Tambah Materi" hide-footer>
      <form>
        <div class="form-group">
          <label for="namaKegiatan">Judul Materi</label>
          <input
            type="text"
            class="form-control"
            id="namaKegiatan"
            v-model="formMateri.judul"
          />
        </div>
        <div class="form-group">
          <label for="namaKegiatan">Deskripsi</label>
          <b-form-textarea
            type="text"
            id="namaKegiatan"
            class="form-control"
            rows="8"
            v-model="formMateri.deskripsi"
          ></b-form-textarea>
        </div>
        <div class="form-group">
          <label for="namaKegiatan">Pemateri</label>
          <s-select
            :options="pemateri"
            v-model="formMateri.id_pemateri"
            :reduce="(data) => data.id"
            label="nama"
            placeholder="Pilih Pemateri"
          ></s-select>
        </div>
        <div>
          <b-button class="float-right" variant="success" @click="submitData">
            Tambah
          </b-button>
          <b-button
            class="float-right mr-2"
            variant="danger"
            @click="$bvModal.hide('modal-tambah')"
          >
            Batal
          </b-button>
        </div>
      </form>
    </b-modal>

    <b-modal id="modal-edit" title="Edit Materi" hide-footer>
      <form>
        <div class="form-group">
          <label for="namaKegiatan">Judul Materi</label>
          <input
            type="text"
            class="form-control"
            id="namaKegiatan"
            v-model="formMateri.judul"
          />
        </div>
        <div class="form-group">
          <label for="namaKegiatan">Deskripsi</label>
          <b-form-textarea
            type="text"
            class="form-control"
            id="namaKegiatan"
            rows="8"
            v-model="formMateri.deskripsi"
          >
          </b-form-textarea>
        </div>
        <div class="form-group">
          <label for="namaKegiatan">Pemateri</label>
          <s-select
            :options="pemateri"
            v-model="formMateri.id_pemateri"
            :reduce="(data) => data.id"
            label="nama"
            placeholder="Pilih Pemateri"
          ></s-select>
        </div>
        <div>
          <b-button class="float-right" variant="success" @click="submitData">
            Simpan
          </b-button>
          <b-button
            class="float-right mr-2"
            variant="danger"
            @click="$bvModal.hide('modal-edit')"
          >
            Batal
          </b-button>
        </div>
      </form>
    </b-modal>

    <b-modal
      id="modal-materi"
      title="Daftar Materi"
      size="lg"
      ok-only
      ok-title="Tutup"
      ok-variant="danger"
    >
      <div v-if="!file">
        <div
          :class="['dropZone', dragging ? 'dropZone-over' : '']"
          @dragenter="dragging = true"
          @dragleave="dragging = false"
        >
          <div class="dropZone-info" @drag="onChange">
            <span class="fa fa-cloud-upload dropZone-title"></span>
            <span class="dropZone-title">Drop file or click to upload</span>
            <div class="dropZone-upload-limit-info">
              <div>Format file PDF dan MP4</div>
              <div>Maksimum ukuran file 32MB</div>
            </div>
          </div>
          <input type="file" @change="onChange" accept=".pdf, .mp4" />
        </div>
      </div>
      <div v-else class="dropZone-uploaded">
        <div class="dropZone-uploaded-info">
          <span class="dropZone-title">{{ file.name }}</span>
          <div class="row mt-5">
            <div class="col-12">
              <b-form-input
                v-model="nama_materi"
                placeholder="Masukkan Nama Materi"
              ></b-form-input>
            </div>
            <div class="col-12 text-center mt-4">
              <button
                type="button"
                class="btn btn-danger m-1 removeFile"
                @click="removeFile"
              >
                Hapus
              </button>

              <button
                type="button"
                class="btn btn-success m-1 removeFile"
                @click="uploadFile"
              >
                Upload
              </button>
            </div>
            <div class="col-6"></div>
          </div>
        </div>
      </div>
      <div class="card mt-5" v-for="(data, i) in dataFileMateri" :key="i">
        <div
          v-if="
            get_url_extension(data.file_materi) == 'pdf' ||
            get_url_extension(data.file_materi) == 'PDF'
          "
          style="
            background-color: #eeefef;
            display: flex;
            align-items: center;
            justify-content: space-between;
          "
        >
          <div class="p-5" style="display: flex; align-items: center">
            <img
              class="mx-10"
              width="24"
              src="https://img.icons8.com/external-vectorslab-flat-vectorslab/53/external-pdf-file-format-files-and-folders-vectorslab-flat-vectorslab.png"
              alt="external-pdf-file-format-files-and-folders-vectorslab-flat-vectorslab"
            />
            <!-- <i
            class="far fa-file mx-10"
            style="color: red; font-size: 24px"
          ></i> -->
            <a
              :href="data.file_materi"
              target="_blank"
              style="font-weight: 400; font-size: 16px"
              >{{ data.nama_materi }}</a
            >
          </div>
          <button @click="deleteFileMateri(data.id)">
            <i
              class="flaticon2-trash mx-10"
              style="color: #515151; font-size: 20px"
            ></i>
          </button>
        </div>
        <div
          v-else-if="
            get_url_extension(data.file_materi) == 'mp4' ||
            get_url_extension(data.file_materi) == 'MP4'
          "
          style="
            background-color: #eeefef;
            display: flex;
            align-items: center;
            justify-content: space-between;
          "
        >
          <div class="p-5" style="display: flex; align-items: center">
            <img
              class="mx-10"
              width="24"
              src="https://img.icons8.com/color/48/play-button-circled--v1.png"
              alt="play-button-circled--v1"
            />
            <!-- <i
            class="far fa-file-video mx-10"
            style="color: red; font-size: 24px"
          ></i> -->
            <a
              :href="data.file_materi"
              target="_blank"
              style="font-weight: 400; font-size: 16px"
              >{{ data.nama_materi }}</a
            >
          </div>
          <button @click="deleteFileMateri(data.id)">
            <i
              class="flaticon2-trash mx-10"
              style="color: #515151; font-size: 20px"
            ></i>
          </button>
        </div>
      </div>
    </b-modal>
  </div>
  <!-- <div class="row">
    <div class="col-12">
      <b-button class="float-right" variant="success" @click="showModal"
        >Tambah Materi</b-button
      >
    </div>

    <div class="col-12 mt-5">
      <div
        class="card card-custom gutter-b"
        v-for="(item, index) in getMateri"
        :key="item.id"
      >
        <div class="accordion" role="tablist">
          <b-card no-body class="">
            <b-card-header header-tag="header" role="tab">
              <b-button
                block
                @click="toggle(index)"
                variant="info"
                class="px-10 pt-5"
                style="
                  background-color: #ffffff;
                  text-align: left;
                  border-color: #cbd0dc;
                  color: black;
                "
              >
                <h3>{{ item.judul }}</h3>
                <i
                  class="flaticon2-arrow float-right rotate-icon"
                  :class="{ rotate: activeIndexes.includes(index) }"
                  style="color: #a9acb4; margin-top: -10px"
                ></i>
                <p style="color: #a9acb4" class="text-wrap">
                  {{ item.deskripsi }}
                </p>
              </b-button>
            </b-card-header>
            <b-collapse
              :visible="activeIndexes.includes(index)"
              role="tabpanel"
            >
              <b-card-body>
                <div v-if="!file">
                  <div
                    :class="['dropZone', dragging ? 'dropZone-over' : '']"
                    @dragenter="dragging = true"
                    @dragleave="dragging = false"
                  >
                    <div class="dropZone-info" @drag="onChange">
                      <span class="fa fa-cloud-upload dropZone-title"></span>
                      <span class="dropZone-title"
                        >Drop file or click to upload</span
                      >
                      <div class="dropZone-upload-limit-info">
                        <div>extension support: pdf</div>
                      </div>
                    </div>
                    <input type="file" @change="onChange" accept=".pdf" />
                  </div>
                </div>
                <div v-else class="dropZone-uploaded">
                  <div class="dropZone-uploaded-info">
                    <span class="dropZone-title">Uploaded</span>
                    <button
                      type="button"
                      class="btn btn-primary removeFile"
                      @click="removeFile"
                    >
                      Remove File
                    </button>
                  </div>
                </div>
                <div
                  class="gutter-b p-5 mt-5"
                  style="
                    background-color: #ebe8e8;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                  "
                >
                  <div style="display: flex; align-items: center">
                    <i
                      class="flaticon2-google-drive-file mx-10"
                      style="color: red; font-size: 40px"
                    ></i>
                    <span style="font-weight: 400; font-size: 16px"
                      >Materi Bimtek A.pdf</span
                    >
                  </div>
                  <button>
                    <i
                      class="flaticon2-trash mx-10"
                      style="color: red; font-size: 20px"
                    ></i>
                  </button>
                </div>
                <div
                  class="gutter-b p-5 mt-5"
                  style="
                    background-color: #ebe8e8;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                  "
                >
                  <div style="display: flex; align-items: center">
                    <i
                      class="flaticon2-arrow mx-10"
                      style="color: red; font-size: 40px"
                    ></i>
                    <span style="font-weight: 400; font-size: 16px"
                      >Video Materi Bimtek A.mp4</span
                    >
                  </div>
                  <button>
                    <i
                      class="flaticon2-trash mx-10"
                      style="color: red; font-size: 20px"
                    ></i>
                  </button>
                </div>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>

       
      </div>

      
    </div>
  </div> -->
</template>
  
 <script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import VueSelect from "vue-select";
import Swal from "sweetalert2";
export default {
  name: "peserta-bmtk",
  components: {
    "s-select": VueSelect,
  },
  data() {
    return {
      token: localStorage.getItem("id_token"),
      url: localStorage.getItem("baseapi"),
      getMateri: [],
      form: {},
      activeIndexes: [],
      file: "",
      dragging: false,
      formMateri: {},
      pemateri: [],
      dataFileMateri: [],
      nama_materi: null,
      id_materi: null,
    };
  },
  methods: {
    getdata() {
      $("#materi").DataTable().destroy();
      this.getMMateri();
    },
    getMMateri() {
      this.getMateri = [];
      this.axios
        .get(this.url + "/materi_pelatihan", {
          headers: {
            xth: this.token,
          },
        })
        .then((response) => {
          this.getMateri = response.data.data;
          this.initDatatable();
        });
    },

    getPemateri() {
      this.axios
        .get(this.url + "/profil_pemateri", {
          headers: {
            xth: this.token,
          },
        })
        .then((response) => {
          this.pemateri = response.data.data;
        });
    },

    submitData() {
      if (this.formMateri.id == null) {
        this.axios
          .post(this.url + "/materi_pelatihan", this.formMateri, {
            headers: {
              xth: this.token,
            },
          })
          .then((response) => {
            this.$bvModal.hide("modal-tambah");
            this.getdata();
            this.formMateri = {};
            Swal.fire({
              icon: "success",
              title: "Data berhasil ditambahkan",
              showConfirmButton: false,
              timer: 1500,
            });
          });
      } else {
        this.axios
          .put(
            this.url + "/materi_pelatihan/" + this.formMateri.id,
            this.formMateri,
            {
              headers: {
                xth: this.token,
              },
            }
          )
          .then((response) => {
            this.$bvModal.hide("modal-edit");
            this.getdata();
            this.formMateri = {};
            Swal.fire({
              icon: "success",
              title: "Data berhasil diubah",
              showConfirmButton: false,
              timer: 1500,
            });
          });
      }
    },

    showModal(id) {
      const dataMateri = this.getMateri.find((p) => p.id === id);
      if (dataMateri) {
        // Update the form with the data
        this.formMateri = {
          id: dataMateri.id,
          judul: dataMateri.judul,
          deskripsi: dataMateri.deskripsi,
          id_pemateri: dataMateri.id_pemateri,
        };
      }
      this.$bvModal.show("modal-edit");
    },

    deleteMateri(id) {
      Swal.fire({
        title: "Apakah anda yakin?",
        text: "Data yang dihapus tidak dapat dikembalikan!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.axios
            .delete(this.url + "/materi_pelatihan/" + id, {
              headers: {
                xth: this.token,
              },
            })
            .then((response) => {
              Swal.fire({
                title: "Terhapus",
                text: "Data berhasil di hapus",
                icon: "success",
              });
              this.getdata();
            });
        }
      });
    },

    showModalFileMateri(id) {
      this.id_materi = id;
      this.axios
        .get(this.url + "/file_materi?filter=id_materi,=," + id, {
          headers: {
            xth: this.token,
          },
        })
        .then((response) => {
          this.dataFileMateri = response.data.data;
          this.$bvModal.show("modal-materi");
        });
    },

    uploadFile() {
      const formData = new FormData();

      console.log(this.file.type);
      if (this.nama_materi == null) {
        Swal.fire({
          title: "Error",
          text: "Nama File Kosong",
          icon: "error",
        });
      } else {
        formData.append("id_materi", this.id_materi);
        formData.append("nama_materi", this.nama_materi);
        formData.append("file_materi", this.file);
        this.axios
          .post(this.url + "/file_materi", formData, {
            headers: {
              xth: this.token,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            Swal.fire({
                title: "Berhasil",
                text: "File berhasil ditambahkan",
                icon: "success",
              });

            this.nama_materi = null;
            this.removeFile();
            this.getDataMateri();
          }).catch((err) =>{
            Swal.fire({
                title: "Gagal",
                text: "File Gagal ditambahkan",
                icon: "error",
              });
          });
      }
    },

    getDataMateri() {
      this.axios
        .get(this.url + "/file_materi?filter=id_materi,=," + this.id_materi, {
          headers: {
            xth: this.token,
          },
        })
        .then((response) => {
          this.dataFileMateri = response.data.data;
        });
    },

    deleteFileMateri(id) {
      Swal.fire({
        title: "Apa anda yakin?",
        text: "Data yang dihapus tidak dapat dikembalikan",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Batalkan",
        confirmButtonText: "Ya, hapus",
      }).then((result) => {
        if (result.isConfirmed) {
          this.axios
            .delete(this.url + "/file_materi/" + id, {
              headers: {
                xth: this.token,
              },
            })
            .then((response) => {
              Swal.fire({
                title: "Terhapus!",
                text: "Data berhasil dihapus.",
                icon: "success",
              });
              this.getDataMateri();
            });
        }
      });
    },
    toggle(index) {
      // Add this
      const position = this.activeIndexes.indexOf(index);
      if (position !== -1) {
        this.activeIndexes.splice(position, 1);
      } else {
        this.activeIndexes.push(index);
      }
    },
    onChange(e) {
      var files = e.target.files || e.dataTransfer.files;

      if (!files.length) {
        this.dragging = false;
        return;
      }

      this.createFile(files[0]);
    },
    createFile(file) {
      if (
        !file.type.match("application/pdf") &&
        !file.type.match("video/mp4")
      ) {
        Swal.fire({
          title: "Error",
          text: "File tidak sesuai dengan format",
          icon: "error",
        });
        this.dragging = false;
        return;
      } else if (file.size > 32000000) {
        Swal.fire({
          title: "Error",
          text: "File lebih dari 32 MB",
          icon: "error",
        });
        this.dragging = false;
        return;
      } else {
        this.file = file;
        console.log(this.file);
        this.dragging = false;
      }
    },
    removeFile() {
      this.file = "";
    },

    addKegiatan(evt) {
      evt.preventDefault();
      // Tambahkan kegiatan baru ke array kegiatan
      this.kegiatan.push({ ...this.newKegiatan, id: Date.now() });
      // Reset form
      this.formMateri = {
        namaMateri: "",
        deskripsi: "",
      };
      // Tutup modal
      this.$bvModal.hide("modal-add");
    },
    initDatatable() {
      setTimeout(() => {
        var t = $("#materi").DataTable({
          pagingType: "full_numbers",
          language: {
            info: "Menampilkan _START_ sampai _END_ dari _TOTAL_ data",
            paginate: {
              first: "Awal",
              last: "Akhir",
              next: "Selanjutnya",
              previous: "Sebelumnya",
            },
          },
          responsive: true,
          destroy: true,
          retrieve: true,
          autoFill: true,
          colReorder: true,
        });
        t.on("order.dt search.dt", function () {
          t.column(0, { search: "applied", order: "applied" })
            .nodes()
            .each(function (cell, i) {
              cell.innerHTML = i + 1;
            });
        }).draw();
      }, 300);
    },

    get_url_extension(url) {
      return url.split(/[#?]/)[0].split(".").pop().trim();
    },
    goToSoal(id) {
      this.$router.push("/materibmtk/soal/" + id);
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Materi Bimtek" }]);
    this.getdata();
    this.getPemateri();
  },
  computed: {
    extension() {
      return this.file ? this.file.name.split(".").pop() : "";
    },
  },
};
</script>
<style scoped>
.rotate-icon {
  transition: transform 0.3s ease-in-out;
}

.rotate {
  transform: rotate(90deg);
}

.btn-spacing {
  margin-right: 10px;
}

.dropZone {
  width: 100%;
  height: 200px;
  position: relative;
  border: 2px dashed #eee;
}

.dropZone:hover {
  border: 2px solid #2e94c4;
}

.dropZone:hover .dropZone-title {
  color: #1975a0;
}

.dropZone-info {
  color: #a8a8a8;
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translate(0, -50%);
  text-align: center;
}

.dropZone-title {
  color: #787878;
}

.dropZone input {
  position: absolute;
  cursor: pointer;
  top: 0px;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.dropZone-upload-limit-info {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.dropZone-over {
  background: #5c5c5c;
  opacity: 0.8;
}

.dropZone-uploaded {
  width: 100%;
  height: 200px;
  position: relative;
  border: 2px dashed #eee;
}

.dropZone-uploaded-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #a8a8a8;
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translate(0, -50%);
  text-align: center;
}

.removeFile {
  width: 100px;
}
</style>